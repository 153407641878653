.Rede--users {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  min-width:700px;
  padding-top: 2rem;
}

.Rede--linha {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.Rede--linha_separador {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Rede--linha_separador_meio {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Rede--coluna {
  display: flex;
  width: 50%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.Rede--coluna_user {
  display: flex;
  width: 50%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.Rede--user {
  position: relative;
  width: 80px;
  height: 80px;
}

.Rede--barraBaixo_esquerda,
.Rede--barraBaixo_direita {
  width: 50%;
  height: 30px;
  border-top: 2px solid var(--kt-warning);
}

.Rede--barraBaixo_esquerda {
  border-left: 2px solid var(--kt-warning);
  border-top-left-radius: 5px;
}

.Rede--barraBaixo_direita {
  border-right: 2px solid var(--kt-warning);
  border-top-right-radius: 5px;
}

.Rede--separadorCima {
  display: block;
  width: 2px;
  height: 30px;
  background: var(--kt-warning);
}
