.custom-table .loadingRow {
  animation-name: fadeInOut;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
