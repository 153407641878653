@import '../../_metronic/assets/sass/core/components/variables';

.dashboard-subscription-container {
  background-color: rgba($primary, 0.05);
  padding: 1rem 3rem 2rem 3rem;
  border-radius: 2rem;
}

.fs-title {
  font-size: 1.1rem;
}

.fs-smaller {
  font-size: 0.8rem;
  line-height: 1rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 2.5rem;
}

.dashboard-img-container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  pointer-events: none;
}

.plans-column-render {
  display: block;
  overflow-y: auto;
  max-height: calc((18.5vh * 3) + (3 * 1.5rem));

  .plan-container {
    height: 18.5vh;
    img {
      height: 100px;
    }
  }
}

.plans-details-render {
  position: relative;
  height: fit-content;
}

.skiptranslate {
  display: none !important;
}

.select-dark option {
  background-color: var(--kt-app-bg-color);
  color: var(--kt-input-color);
  box-shadow: none !important;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.form-gTranslate option {
  background-color: var(--kt-app-bg-color);
  color: var(--kt-input-color);
  box-shadow: none !important;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

#kt_app_body{
  top:0px!important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

@media only screen and (max-width: 1025px) {
  .plans-column-render {
    display: block;
    overflow-y: auto;
    max-height: calc((100px * 2) + (2 * 1.5rem));

    .plan-container {
      height: 100px;
      img {
        height: 50px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .plan-container {
    img {
      display: none;
    }
  }
}
